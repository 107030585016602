<template>
    <enso-table class="box is-paddingless raises-on-hover"
        id="badges">
        <template #image="{ row }">
            <img class="image-badge" :src="'/storage/' +row.image">
        </template>
    </enso-table>
</template>

<script>
import { EnsoTable } from '@enso-ui/bulma';
import { mapState } from 'vuex';

export default {
    name: 'Index',

    components: { EnsoTable },

    computed: {
        ...mapState(['enums', 'user', 'meta']),
    },
};
</script>

<style lang="scss" scoped>
.image-badge{
    height: auto;
    width: 2.7rem;
    border-radius: 50%;
    max-width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;
}
@media (max-width: 768px) {
    .image-badge{
        width: 2rem;
        height: auto;
        border-radius: 50%;
    }
}
</style>
